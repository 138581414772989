


















































import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import MaterialSelect from "@/components/material-select/index.vue";

@Component({
  components: { MaterialSelect },
})
export default class bannerTable extends Vue {
  @PropSync("data", { type: Array }) syncedData!: any;

  handleDelete(index: any) {
    this.syncedData.splice(index, 1);
  }

  handleAdd() {
    this.syncedData.push({
      img: "",
      url: "",
    });
  }

  created() {

    console.log(this.syncedData);
  }
}
