






































import { Component, Vue, PropSync, Watch } from "vue-property-decorator";

@Component({})
export default class listApplicableCities extends Vue {
  @PropSync("data", { type: Array }) syncedData!: any;
  pager = {
    page: 1,
    size: 10,
  };

  get tableData() {
    let arr: any = this.syncedData.slice(
      (this.pager.page - 1) * this.pager.size,
      this.pager.page * this.pager.size
    );

    return arr;
  }

  multipleSelection = [];

  handleDelete(id: any) {
    const index = this.syncedData.findIndex((item: any) => {
      return item.id === id;
    });
    this.syncedData.splice(index, 1);
    this.$emit("update");
  }

  handleDeleteAll() {
    if (this.multipleSelection.length < 1) {
      this.$message.warning("请选择要删除的行");
      return;
    }
    this.multipleSelection.map((item: any) => {
      this.handleDelete(item.id);
    });
    this.$nextTick(() => {
      this.multipleSelection = [];
    });
  }

  handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }

  handleSizeChange(val: any) {
    this.pager.size = val;
  }

  handleCurrentChange(val: any) {
    this.pager.page = val;
  }

  created() {
    // console.log(this.syncedData);
  }
}
