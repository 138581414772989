


























import { Component, Vue, PropSync, Watch } from "vue-property-decorator";

@Component({})
export default class invalidDataTable extends Vue {
  @PropSync("data", { type: Array }) syncedData!: any;

  created() {
    // console.log(this.data);
  }
}
