



























































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ExportData from "@/components/export-data/index.vue";
import { RequestPaging } from "@/utils/util";
import {
  getAListOfReviewedItems,
  getBusinessList,
  getCategoryList,
  getGoodsCategoryList,
  getLongRangeGoodsBrandLists,
} from "@/api/goods";
import { ApprovalStatus } from "@/utils/type";
import { apiShopLists } from "@/api/shop";

@Component({
  components: {
    ExportData,
  },
})
export default class Goods extends Vue {
  queryObj: any = {
    keyword: "",
    category_id: "",
    supplier_id: "",
    goods_type: "",
    brand_id: "",
  };
  tableint: any = "";
  supplierList: any[] = [];
  categoryList: any[] = [];
  typeList: any = [];
  tabCount = {
    all: 0,
    success: 0,
    error: 0,
    wait: 0,
  };
  tag_type = {
    1: "",
    2: "warning",
    3: "danger",
    4: "success",
  };
  shop_list: any = [];
  brandList: any = [];
  multipleSelection: any = [];

  b_loading: boolean = false;
  pager = new RequestPaging();
  activeName: any = "all";
  apiGoodsLists = getAListOfReviewedItems;
  getList(page?: number, type?: number): void {
    page && (this.pager.page = page);
    this.tableint = ApprovalStatus[this.activeName];
    this.pager
      .request({
        callback: getAListOfReviewedItems,
        params: {
          seach_type: ApprovalStatus[this.activeName],
          ...this.queryObj,
        },
      })
      .then((res: any) => {
        this.tabCount = res?.extend;
      });
  }

  // 批量挑选
  pickAllGoods() {
    this.$emit("pickGoods", this.multipleSelection);
  }
  // 挑选
  pickGoods(item: any) {
    this.$emit("pickGoods", [item]);
  }

  handleSelect(val: any[]) {
    this.multipleSelection = val;
  }

  handleReset() {
    this.queryObj = {
      keyword: "",
      category_id: "",
      supplier_id: "",
    };
    this.getList();
  }

  getGoodsCateList() {
    getCategoryList({}).then((res: any) => {
      this.categoryList = res?.lists;
    });
    getBusinessList({}).then((res: any) => {
      this.supplierList = res;
    });
  }

  // 商城
  async getShop() {
    this.pager.loading = true;
    const res = await apiShopLists({
      page_type: 0,
      app_id: 10,
    });
    this.pager.loading = false;
    this.shop_list = res.lists;
  }

  // 品牌
  async getBrandLists(name = "") {
    const res = await getLongRangeGoodsBrandLists({
      name: name,
    });
    const lists = res.lists.splice(1, 100);
    this.brandList = lists;
  }

  async searchBrand(query: string) {
    this.b_loading = true;
    await this.getBrandLists(query);
    this.b_loading = false;
  }

  async created() {
    this.pager.page = Number(this.$route.query.page) || 1;
    this.pager.size = Number(this.$route.query.size) || 10;
    await this.getShop();
    this.getBrandLists("");
    this.getGoodsCateList();
    this.getList();
    this.typeList = [
      { table: "待审核", value: 0 },
      { table: "审核失败", value: 1 },
      { table: "审核成功", value: 2 },
    ];
  }
}
