





































































import { Component, Vue, PropSync, Watch } from "vue-property-decorator";

@Component({})
export default class blacklistCategory extends Vue {
  @PropSync("data", { type: Array }) syncedData!: any;

  multipleSelection = [];

  handleDelete(index: any) {
    this.syncedData.splice(index, 1);
  }

  handleDeleteAll() {
    this.multipleSelection.map((item) => {
      this.syncedData.splice(item, 1);
    });
    this.multipleSelection = [];
  }

  handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }
  handleAdd() {
    this.syncedData.push({
      first: "",
      second: [],
    });
  }

  created() {
    console.log(this.syncedData);
  }
}
