












































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import setGoodsPrice from "@/components/set-goods-price.vue";
import transfer from "@/components/transfer.vue";
import { apiShopLists } from "@/api/shop";

import {
    getLongRangeGoodsMscGoodsLists,
    apiCategoryLists,
    apiSourceGoodsGroupListsNopage,
    apiSourceGoodsEditMcsGoods,
    apiSourceGoodsDeleteGoods,
    apiSourceGoodsEditMcsGoodsStatus,
    apiGetGoodsPrice,
    apiSetGoodsPrice,
    apiPlatformPickOut,
    getLongRangeGoodsBrandLists,
} from "@/api/goods";
import { deepClone } from "@/utils/util.ts";

@Component({
    components: {
        lsPagination,
        LsDialog,
        setGoodsPrice,
        transfer,
    },
})
export default class MyselectionLibraryIndex extends Vue {
    getLongRangeGoodsMscGoodsLists = getLongRangeGoodsMscGoodsLists;
    $refs!: { priceForm: any; pickForm: any };

    pager: RequestPaging = new RequestPaging();
    count: any = {
        inStore: 0,
        newThisMonth: 0,
        onSale: 0,
        soldOut: 0,
    };
    searchObj: any = {
        status: 0,
        category_id: "", //分类id
        brand_id: "", //品牌id
        goods_name: "", //商品名称
        tag: "",
        mcs_spu: "", //商品spu
        min_price: "", //最低价
        max_price: "", //最高价
        min_profit: "",
        max_profit: "",
    };
    dialogPrice: boolean = false;
    dialogGoods: boolean = false;
    price_loading: boolean = false;
    shop_loading: boolean = false;
    dialog_title: string = "";
    is_batch: boolean = false;
    is_pick: boolean = false;
    shop_list: any = [];
    pickForm: any = {
        id_list: [],
        shop_data: [],
    };
    priceForm: any = {
        shop_price_type: "",
        price_method: "",
        value: {
            price_list: {
                price_type: "1",
                price_ratio: "1",
                price_add_sub: "1",
                price_number: "1",
            },
            price_rounding: "1",
            reserve_decimal: 2,
        },
        sku_list: [
            {
                cost_price: "",
                guide_price: "",
                sku: "",
                sku_name: "",
            },
        ],
    };

    //分类列表
    categoryList: any = [];
    b_loading: boolean = false;
    brandList: any = [];
    //多选框数据
    multipleSelection: any[] = [];

    get statusText() {
        return this.searchObj.status === 1
            ? "是否上架商品？"
            : "下架商品停止售卖，不在商城显示，确认继续？";
    }

    //选品库数据
    getGoodsLists() {
        const searchObj = this.searchObj;
        const category_arr = searchObj.category_id;
        const data = {
            status: searchObj.status,
            first_category_id: category_arr[0],
            second_category_id: category_arr.length > 1 ? category_arr[1] : "",
            category_id:
                category_arr.length > 2
                    ? category_arr[category_arr.length - 1]
                    : "",
            goods_name: searchObj.goods_name,
            mcs_spu: searchObj.mcs_spu,
            min_price: searchObj.min_price,
            max_price: searchObj.max_price,
            min_profit: searchObj.min_profit,
            max_profit: searchObj.max_profit,
            tag: searchObj.tag,
            brand_id: searchObj.brand_id,
        };

        this.pager
            .request({
                callback: getLongRangeGoodsMscGoodsLists,
                params: {
                    ...data,
                },
            })
            .then((res) => {
                this.count = res.extend;
            });
    }

    //表格全选
    handleSelectionChange(value: any) {
        this.multipleSelection = value;
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getGoodsLists();
    }

    // 排序
    sortChange({ prop, order }: any) {
        this.searchObj.order = order;
        if (order === "descending") {
            this.searchObj.order = "desc";
        }
        if (order === "ascending") {
            this.searchObj.order = "asc";
        }
        this.searchObj.prop = prop;
        this.getGoodsLists();
    }

    // 批量挑选
    pickAllGoods() {
        this.$emit("pickGoods", this.multipleSelection);
    }
    // 挑选
    pickGoods(item: any) {
        this.$emit("pickGoods", [item]);
    }

    //获取分类
    getCategoryList() {
        apiCategoryLists({ page_type: 1 }).then((res: any) => {
            res?.lists.forEach((item: any) => {
                item.sons &&
                    item.sons.forEach((sitem: any) => {
                        delete sitem.sons;
                    });
            });
            this.categoryList = res?.lists;
        });
    }

    // 商城
    async getShop() {
        const res = await apiShopLists({
            page_type: 0,
            app_id: 10,
        });
        this.shop_list = res.lists;
    }

    async searchBrand(query: string) {
        this.b_loading = true;
        await this.getBrandLists(query);
        this.b_loading = false;
    }
    // 品牌
    async getBrandLists(name = "") {
        const res = await getLongRangeGoodsBrandLists({
            name: name,
        });
        const lists = res.lists.splice(1, 100);
        this.brandList = lists;
    }

    async getOtherLists() {
        await this.getShop();
        await this.getBrandLists("");
        await this.getCategoryList();
    }
    /** S ods **/
    async created() {
        this.pager.page = Number(this.$route.query.page) || 1;
        this.pager.size = Number(this.$route.query.size) || 10;
        this.getOtherLists();
        this.getGoodsLists();
    }
}
