























import {
    Component,
    Vue,
    PropSync,
    Watch,
    Emit,
    Prop,
} from "vue-property-decorator";
import { RequestPaging } from "@/utils/util";
import { getGoodsCategoryList, getGoodsList } from "@/api/goods";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { apiAddProductProfileProduct } from "@/api/productFile";
import platformGoods from "./platform-goods.vue";
import selfGoods from "./self-goods.vue";
@Component({
    components: {
        lsPagination,
        LsDialog,
        platformGoods,
        selfGoods,
    },
})
export default class addGoodsTable extends Vue {
    @Prop({ default: 1 }) listType!: number;
    @Prop({ default: "" }) packgeCategoryId!: number;
    @PropSync("show", { type: Boolean, default: false }) syncShow!: any;
    @PropSync("fileNumber", { type: String, default: "" }) syncFileNumber!: any;
    pager: RequestPaging = new RequestPaging();
    activeName = "1";
    searchObj: any = {
        profile_number: "", //运营包id
        name: "", //商品名称
        supplier_name: "", //自营管理供应商
        status: "", //商品状态
        min_price: "", //最低价
        max_price: "", //最高价
        min_profit: "", //最低利润
        max_profit: "", //最高利润
        is_supplier_up: "", //是否为平台/自营1平台0自营
        category_id: "", //分类id
    };
    categoryList: any[] = [];
    categoryListFlat: any[] = [];
    //全选所有
    allCheck: boolean = false;
    form = {
        category: [],
        giftPool: "",
        stallPrice: ["", ""],
    };
    checked = false;

    tableData = [{ test: 1 }, { test: 2 }, { test: 3 }];

    multipleSelection = [];

    async goodsPackgeGoodsAddCategoryGoods(
        arr: Array<object>,
        type: number
    ): Promise<void> {
        if (arr.length === 0) {
            this.$message.warning("请选中商品");
            return;
        }
        const data: any = {
            skuList: [],
            number: "",
        };
        arr.map((item: any) => {
            data.skuList.push({
                type: type,
                sku: item.id,
                grade: type==1?item.min_lineation_price:item.min_market_reference_price,
                product_name: type == 1 ? item.goods_name : item.name,
                min_cost_price: type==1?item.min_price:item.min_cost_price,
                max_cost_price: type==1?item.max_price:item.max_cost_price,
                min_market_reference_price:  type == 1 ? item.min_lineation_price:item.min_market_reference_price,
                max_market_reference_price: type == 1 ? item.min_lineation_price:item.max_market_reference_price,
                min_market_reference_profit: type == 1 ? item.min_profit:item.min_market_reference_profit,
                max_market_reference_profit: type == 1 ? item.max_profit:item.max_market_reference_profit,
            });
        });
        data.number = this.syncFileNumber;
        const res = await apiAddProductProfileProduct(data); //新增
        if (res) {
            this.selectSuccessfully(data);
            this.getList();
        }
    }

    @Emit()
    selectSuccessfully(item: any) {
        return item;
    }

    //获取分类列表
    async getCategoryList(): Promise<void> {
        console.log(this.listType, "this.listType");
        const res = await getGoodsCategoryList({});
        this.categoryList = res?.lists;
        this.categoryListFlatFun(res?.lists);
    }
    //获取选品列表
    getList(page?: number) {
        page && (this.pager.page = page);
        this.searchObj.profile_number = this.syncFileNumber;
        this.pager.request({
            callback: getGoodsList,
            params: {
                ...this.searchObj,
            },
        });
    }
    //分类过滤
    categoryListFlatFun(tree: any) {
        tree = JSON.parse(JSON.stringify(tree));
        let queen: any = [];
        let out = [];
        queen = queen.concat(tree);
        while (queen.length) {
            let first = queen.shift();
            if (first.sons) {
                queen = queen.concat(first.sons);
                delete first.sons;
            }
            out[first.id] = first.name;
        }
        this.categoryListFlat = out;
    }
    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getList();
    }
    //分类过滤
    categoryListFlatFilters(str: string) {
        const arr = str.split(",");
        let res: any = "";
        arr.map((item: any) => {
            res += `<p>${this.categoryListFlat[item]}</p>`;
        });
        return res;
    }

    //左下角全选
    allCheckbox(value: boolean) {
        const tableRef = this.$refs.paneTable as any;
        value ? tableRef.toggleAllSelection() : tableRef.clearSelection();
    }
    //表格全选事件
    handleSelectionChange(value: any) {
        this.multipleSelection = value;
        let checkedCount = value.length;
        this.allCheck = checkedCount === this.pager.lists.length;
    }
    @Emit()
    submit() {
        console.log(this.form);
        return this.multipleSelection;
    }

    search() {}

    created() {
        this.getList();
        this.getCategoryList();
    }
}
