














































































































































import { Component, Vue, PropSync, Watch, Emit } from "vue-property-decorator";
import BannerTable from "./bannerTable.vue";
import CommodityDetailTable from "./commodityDetailTable.vue";
import InvalidDataTable from "./invalidDataTable.vue";
import {
    apiGenerateJumpLink,
    apiGetPriceRules,
    apiGetProductProfileProduct,
    apiInvalidDataProduct,
} from "@/api/productFile";
import { RequestPaging } from "@/utils/util";

@Component({
    components: {
        BannerTable,
        CommodityDetailTable,
        InvalidDataTable,
    },
})
export default class commodityDetail extends Vue {
    @PropSync("data", { type: Object }) syncedData!: any;
    pager: RequestPaging = new RequestPaging();
    selectSuccessfullyall(item: any) {
        this.getInvalidDataProduct();
        this.generateJumpLink();
        this.updateList(item);
    }
    async getInvalidDataProduct(page?: number) {
        page && (this.pager.page = page);
        await this.pager.request({
            callback: apiInvalidDataProduct,
            params: {
                ...{ profile_number: this.syncedData.fileNumber },
            },
        });
    }
    @Emit()
    updateData(item: any) {
        return item;
    }
    @Emit()
    updateList(item: any) {
        return item;
    }
    async generateJumpLink() {
        const links = await apiGenerateJumpLink({
            number: this.syncedData.fileNumber,
        });
        this.updateData({ type: "linkAddressClose", data: links.link });
        this.updateData({ type: "linkAddressOpen", data: links.public_link });
        const priceRules = await apiGetPriceRules({
            number: this.syncedData.fileNumber,
        });
        console.log(priceRules);
        this.updateData({
            type: "unitProfitRate",
            data: {
                costPriceMinimumUnitPrice: priceRules.costPriceMinimumUnitPrice,
                costPriceMaximumUnitPrice: priceRules.costPriceMaximumUnitPrice,
                sellingPriceMinimumUnitPrice:
                    priceRules.sellingPriceMinimumUnitPrice,
                sellingPriceMaximumUnitPrice:
                    priceRules.sellingPriceMaximumUnitPrice,
                minimumProfitRate: priceRules.minimumProfitRate,
                maximumProfitRate: priceRules.maximumProfitRate,
            },
        });
    }
    created() {
        this.getInvalidDataProduct();
    }
}
