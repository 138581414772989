






























































































import { Component, Vue, PropSync, Watch, Prop } from "vue-property-decorator";
import { apiProductFileGetCinemaList } from "@/api/productFile";
import { cinemaBatchList } from '@/api/movie'

@Component({})
export default class applicableCityCinemaList extends Vue {
  @Prop() cityAllList!: any[];
  @Prop() privateTypeEnums!: any[];
  @PropSync("data", { type: Array }) syncedData!: any;

  pager = {
    page: 1,
    size: 10,
  };

  key = 0;

  get tableData() {
    let arr: any = this.syncedData.slice(
      (this.pager.page - 1) * this.pager.size,
      this.pager.page * this.pager.size
    );

    return arr;
  }

  multipleSelection = [];

  handleDelete(key: any) {
    const index = this.syncedData.findIndex((item: any) => {
      return item.key === key;
    });

    this.syncedData.splice(index, 1);
  }

  handleDeleteAll() {
    if (this.multipleSelection.length < 1) {
      this.$message.warning("请选择要删除的行");
      return;
    }
    this.multipleSelection.map((item: any) => {
      this.handleDelete(item.key);
    });
    this.$nextTick(() => {
      this.multipleSelection = [];
    });
  }

  handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }
  handleAdd() {
    this.syncedData.push({
      city: "",
      cinema: "",
      methodExchange: [],
      cinemaList: [],
      key: this.key++,
    });
  }

  handleSizeChange(val: any) {
    this.pager.size = val;
  }

  handleCurrentChange(val: any) {
    this.pager.page = val;
  }

  async handleCityChange(index: any, value: any) {
    const cityId = this.cityAllList.find(
      (item) => +item.value === +value
    ).value;
    const res = await cinemaBatchList({
      city_id:[cityId],
      page_type: 2,
    });
    this.syncedData[index].cinemaList = res.lists;
  }

  created() {
    this.key = this.syncedData.length;
  }
}
