
















































































import lsPagination from "@/components/ls-pagination.vue";
import { Component, Vue, PropSync, Watch, Emit } from "vue-property-decorator";
import AddGoodsTable from "./addGoodsTable.vue";
import {
    apiAddProductProfileProduct,
    apiDelProductProfileProduct,
    apiEditFileProductsGroupName,
    apiGetProductProfileProduct,
} from "@/api/productFile";
import { RequestPaging } from "@/utils/util";
import { getGoodsList } from "@/api/goods";

@Component({
    components: {
        AddGoodsTable,
        lsPagination,
    },
})
export default class commodityDetailTable extends Vue {
    @PropSync("data", { type: Array }) syncedData!: any;
    @PropSync("fileNumber", { type: String }) syncedFileNumber!: any;
    pager: RequestPaging = new RequestPaging();
    searchObj: any = {
        profile_number: "", //运营包id
    };
    checked = false;
    dialogVisible = false;

    multipleSelection = [];

    async handleDelete(index: any) {
        await apiDelProductProfileProduct(this.pager.lists[index]);
        this.getProductProfileProduct();
        // this.syncedData.splice(index, 1)
    }
    handleEditAll() {
        this.$prompt("请输入分组名称", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            inputValue: this.pager.lists[0]
                ? this.pager.lists[0].group_name
                : "",
        }).then(({ value }: any) => {
            this.editGroupName(value);
        });
    }
    async editGroupName(value: any) {
        if (this.multipleSelection.length <= 0) {
            this.$message({
                type: "warning",
                message: "请选择要编辑的分组",
            });
            return;
        }
        let ids: Array<any> = [];
        this.multipleSelection.forEach((item: any) => {
            ids.push(item.id);
        });
        await apiEditFileProductsGroupName({
            group_name: value,
            number: this.syncedFileNumber,
            ids: ids,
        });
        this.getProductProfileProduct();
    }
    handleDeleteAll() {
        this.multipleSelection.map((item) => {
            this.syncedData.splice(item, 1);
        });
        this.multipleSelection = [];
    }

    handleSelectionChange(val: any) {
        this.multipleSelection = val;
    }

    handleAdd() {
        this.syncedData.push({
            first: "",
            second: [],
        });
    }

    save(list: any) {
        // console.log(list);
        // list.map((item: any) => {
        //   //处理item
        //   this.syncedData.push(item);
        // });

        this.dialogVisible = false;
    }

    selectSuccessfully(item: any) {
        this.getProductProfileProduct();
    }
    @Emit()
    selectSuccessfullyall(item: any) {
        return item;
    }

    async getProductProfileProduct() {
        this.searchObj.profile_number = this.syncedFileNumber;
        await this.pager.request({
            callback: apiGetProductProfileProduct,
            params: {
                ...this.searchObj,
            },
        });
        this.selectSuccessfullyall(this.pager.lists);
    }

    created() {
        this.getProductProfileProduct();
    }
}
