import { render, staticRenderFns } from "./commodityDetailTable.vue?vue&type=template&id=cce6f1be&scoped=true&"
import script from "./commodityDetailTable.vue?vue&type=script&lang=ts&"
export * from "./commodityDetailTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cce6f1be",
  null
  
)

export default component.exports