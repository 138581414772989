import { render, staticRenderFns } from "./bannerTable.vue?vue&type=template&id=4c46b27f&scoped=true&"
import script from "./bannerTable.vue?vue&type=script&lang=ts&"
export * from "./bannerTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c46b27f",
  null
  
)

export default component.exports